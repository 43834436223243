<template>
  <div class="w-full">
    <form-edit :value="fullName" :actions="false" />
    <div class="w-full flex flex-col border bg-white rounded-sm min-h-96">
      <div class="flex space-x-2 bg-white min-h-96 p-2">
        <div class="w-1/2 flex flex-col h-full">
          <h3 class="text-sm font-semibold">Stammdaten</h3>
          <div class="rounded bg-gray-100 mt-1 p-2 pb-9">
            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/2">
                <h3>Personalnummer</h3>
                <input type="text" class="rounded p-1 border" v-model="personal.personal_number" />
              </div>

              <div class="flex flex-col w-1/2">
                <h3>Zivil Status</h3>
                <form-select
                  placeholder="Zivilstand wählen"
                  :options="[
                    { value: 'Ledig', label: 'Ledig' },
                    { value: 'Verheiratet', label: 'Verheiratet' },
                    { value: 'Geschieden', label: 'Geschieden' },
                    { value: 'Verwitwet', label: 'Verwitwet' },
                  ]"
                  class="rounded border"
                  v-model="personal.civil_status"
                />
              </div>
            </div>

            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/2">
                <h3>Vorname</h3>
                <input
                  type="text"
                  class="rounded p-1 border"
                  :class="{ 'border-red-500': required.first_name }"
                  v-model="personal.first_name"
                />
              </div>

              <div class="flex flex-col w-1/2">
                <h3>Nachname</h3>
                <input
                  type="text"
                  class="rounded p-1 border"
                  :class="{ 'border-red-500': required.last_name }"
                  v-model="personal.last_name"
                />
              </div>
            </div>

            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/2">
                <h3>Adress</h3>
                <textarea class="rounded border p-1" rows="5" v-model="personal.address"></textarea>
              </div>

              <div class="flex flex-col w-1/2">
                <div class="flex space-x-2 my-3">
                  <div class="flex flex-col w-1/4">
                    <h3>Postleihzahl</h3>
                    <input type="text" class="rounded p-1 border" v-model="personal.postcode" />
                  </div>

                  <div class="flex flex-col w-3/4">
                    <h3>Ort</h3>
                    <input type="text" class="rounded p-1 border" v-model="personal.place" />
                  </div>
                </div>

                <div class="flex flex-col">
                  <h3>Geburtsdatum</h3>
                  <date-picker
                    v-model="personal.birth_date"
                    :input-class="
                      `border rounded p-1 text-xs appearance-none focus:outline-none w-full ${required.birth_date &&
                        'border-red-500'}`
                    "
                    placeholder="Geburstdatum"
                  />
                </div>
              </div>
            </div>

            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/2">
                <h3>Eintrittsdatum</h3>
                <date-picker
                  v-model="personal.start_date"
                  :input-class="
                    `border rounded p-1 text-xs appearance-none focus:outline-none w-full ${required.start_date &&
                      'border-red-500'}`
                  "
                  placeholder="Eintrittsdatum"
                />
              </div>

              <div class="flex flex-col w-1/2">
                <h3>Austrittsdatum</h3>
                <date-picker
                  v-model="personal.leaving_date"
                  input-class="border rounded p-1 text-xs appearance-none focus:outline-none w-full"
                  placeholder="Austrittsdatum"
                />
              </div>
            </div>

            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/2">
                <h3>AHV Nummer</h3>
                <input type="text" class="rounded p-1 border" v-model="personal.si_number" />
              </div>

              <div class="flex flex-col w-1/2">
                <h3>Bank</h3>
                <input type="text" class="rounded p-1 border" v-model="personal.bank_institution" />
              </div>
            </div>

            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/2">
                <h3>IBAN</h3>
                <input type="text" class="rounded p-1 border" v-model="personal.bank_account" />
              </div>
            </div>
          </div>
        </div>

        <div class="w-1/2 flex flex-col h-full">
          <div class="flex flex-col">
            <h3 class="text-sm font-semibold">Kommunikation</h3>
            <div class="rounded bg-gray-100 my-1 p-2 mb-8">
              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-1/2">
                  <h3>E-Mail</h3>
                  <input type="text" class="rounded p-1 border" v-model="personal.email" />
                </div>
              </div>

              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-1/2">
                  <h3>Geschäft Telefon</h3>
                  <input type="text" class="rounded p-1 border" v-model="personal.business_phone" />
                </div>

                <div class="flex flex-col w-1/2">
                  <h3>Geschäft Mobile</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.business_mobile"
                  />
                </div>
              </div>

              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-1/2">
                  <h3>Privat Telefon</h3>
                  <input type="text" class="rounded p-1 border" v-model="personal.private_phone" />
                </div>

                <div class="flex flex-col w-1/2">
                  <h3>Privat Mobile</h3>
                  <input type="text" class="rounded p-1 border" v-model="personal.private_mobile" />
                </div>
              </div>
            </div>

            <h3 class="text-sm font-semibold">Notfall Kontakt</h3>
            <div class="rounded bg-gray-100 my-1 p-2">
              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-1/2">
                  <h3>Vorname</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.emergency_first_name"
                  />
                </div>

                <div class="flex flex-col w-1/2">
                  <h3>Nachname</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.emergency_last_name"
                  />
                </div>
              </div>

              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-1/2">
                  <h3>Telefon</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.emergency_phone"
                  />
                </div>

                <div class="flex flex-col w-1/2">
                  <h3>E-Mail</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.emergency_email"
                  />
                </div>
              </div>

              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-full">
                  <h3>Anmerkung</h3>
                  <textarea class="rounded border p-1" rows="5"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex justify-start space-x-2 text-xs text-white px-4 py-2 border-t bg-gray-100 border-gray-400"
      >
        <a href="#" @click.prevent="save" class="rounded-sm bg-blue-900 px-2 py-1">Speichern</a>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { createOne } from '@/services/personal'

export default {
  name: 'personal-edit',
  components: {},
  data() {
    return {
      required: {
        first_name: false,
        last_name: false,
        birth_date: false,
        start_date: false,
      },
      personal: {
        personal_number: null,
        civil_status: null,
        first_name: null,
        last_name: null,
        address: null,
        postcode: null,
        place: null,
        business_phone: null,
        business_mobile: null,
        private_phone: null,
        private_mobile: null,
        birth_date: null,
        start_date: null,
        leaving_date: null,
        ahv_number: null,
        bank_institute: null,
        bank_account: null,
        emergency_first_name: null,
        emergency_last_name: null,
        emergency_phone: null,
        emergency_email: null,
        emergency_note: null,
      },
    }
  },
  mounted() {
    this.init()
    this.updateTitle([
      { crumb: 'Personal', link: '/personal' },
      { crumb: 'Neu', link: null },
    ])
    this.updateSubHeader(true)
  },
  methods: {
    init() {},
    save() {
      if (this.validate()) {
        // this.personal.user = this.profile.id
        createOne(this.personal).then(response => {
          if (response.status === 201) {
            // this.$toast.success(
            //   `${response.data.data.first_name} ${response.data.data.last_name} wurde erfolgreich erfasst`
            // )

            this.$emit('close-modal', {
              show: false,
              redirect: `/personal/${response.data.data.id}`,
            })
          } else {
            this.$emit('close-modal', { show: false })
            // this.$toast.error('Error while updating.')
          }
        })
      }
    },
    validate() {
      let validated = true

      _.each(this.required, (value, required) => {
        if (!this.personal[required]) {
          this.required[required] = true
          validated = false
        }
      })
      return validated
    },
  },
  computed: {
    personalNumber() {
      return this.personal.personal_number ? `${this.personal.personal_number} -` : ''
    },
    fullName() {
      return `${this.personal.first_name || ''} ${this.personal.last_name || ''}`
    },
  },
  watch: {
    personal: {
      handler: function(n) {
        _.each(n, (value, field) => {
          if (_.has(this.required, field)) {
            this.required[field] = value ? false : true
          }
        })
      },
      deep: true,
    },
  },
}
</script>

<style></style>
